/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // Select all links with hashes ** SLOW SCROLL
        $('a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

        var elementPosition = $('.footer-callout').offset();

        $(window).scroll(function(){
          if($(window).scrollTop() > (elementPosition.top + (-600))){
            $('a.backtotop').css('position','fixed').css('top','300px');
          } else {
            $('a.backtotop').css('position','absolute').css('top','-300px');
          }
        });




        $('.department .directory .member article').matchHeight();

        $('a').each(function() {
				   var a = new RegExp('/' + window.location.host + '/');
				   if (!a.test(this.href)) {
				   	$(this).attr("rel","external").attr("target","_blank");
				   }
				});

        $('#searchbar-label').click(function() {
          event.preventDefault();
          if ($("#searchbar-input").hasClass('focus')) {
            $("#searchbar-input").removeClass('focus');
          } else {
            $("#searchbar-input").addClass('focus');
          }
          return false;
        });


        $('.navigation.posts-navigation .nav-links .nav-previous a').html('<span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span><span class="sr-only">Previous</span> Previous');

        $('.navigation.posts-navigation .nav-links .nav-next a').html('Next <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span><span class="sr-only">Next</span>');

				$('.block-related-pages .related-page article').matchHeight();

				$('.block-navigation-box .coll').matchHeight();

				// TABS LINK TO ANCHOR
        if(location.hash !== null && location.hash !== ""){
          $('.collapse').removeClass('in');
          $(location.hash + '.collapse').collapse('show');
          $('html, body').animate({
            scrollTop: $(location.hash).offset().top - 200
          });
        }
        $('.block-membership.panel-group .panel-heading a').click(function (e) {
          window.location.hash = this.hash;
        });


/*
        $('.offcanvas .nav-primary > li.menu-item-has-children > a').click(function() {
          event.preventDefault();
          if ($(this).next().is(':visible')) {
            $(this).next('.sub-menu').hide();
          } else {
            $(this).next().show();
          }
          return false;
        });
*/

        $('.content-info .nav-social li a').addClass('ss-icon');
        $('#menu-primary-navigation .menu-search a').html('<i class="fa fa-search" aria-hidden="true"></i>');
        $('#menu-primary-navigation .menu-search').removeClass('hidden');


        function validateEmail($email) {
          var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
          return emailReg.test( $email );
        }

        $('#blueimp-gallery').on('slide', function (event, index, slide) {
          $(this).children('.description').html($('nav.slideshow a').eq(index).data('description'));
          });

        //----------- DONATION FORM

/*
        setTimeout(function(){
            $("#checkout .amounts button.default-amount").click();
        },1);
*/

//         $("#checkout .amounts button.default-amount")[0].click();

        $('#checkout .amounts button').click(function(event){
          event.preventDefault();
          $('#checkout .amounts button').removeClass('active');
          $(this).addClass('active');
          var donationStr=$(this).val();
          var donationAmt=donationStr.replace("$","").replace(",","");
//           $('#checkout .amounts #amountstr').attr('placeholder',donationStr).removeClass('empty');
          $('#checkout .amounts #amountstr').val(donationStr);
          $('#checkout .amounts #amount').val(donationAmt);
          $('#checkout section.stage-1 .next').removeAttr('disabled');
          return false;
        });
        $('#checkout .amounts #amountstr').blur(function() {
//           alert("b");
          if ($(this).val()==="") {
            $('#checkout section.stage-1 .next').attr('disabled',true);
          } else {
            $('#checkout .amounts #amount').val($(this).val().replace("$","").replace(",",""));
            if ($('#checkout .amounts #amount').val()==="") {
              $('#checkout section.stage-1 .next').removeAttr('disabled');
            }
          }
        });
        $('#checkout .amounts #amountstr').on('keypress', function(e) {
            return e.which !== 13;
        });
        $('#checkout .amounts #amountstr').focus(function() {
//           alert("f");
          $('#checkout .amounts button').removeClass('active');
          $('#checkout section.stage-1 .next').removeAttr('disabled');
        });
        $('#checkout .amounts #amountstr').change(function() {
          if ($(this).val()==="") {
            $('#checkout section.stage-1 .next').attr('disabled',true);
          } else {
            $('#checkout section.stage-1 .next').removeAttr('disabled');
//             alert($(this).val());
            $('#checkout .amounts #amount').val($(this).val());
          }
        });

        $('#checkout button.next').click(function(event){
          event.preventDefault();
          var newSection = $(this).val();
          $('#checkout section').slideUp();
          $('#checkout .description').removeClass('active');
          $('#checkout .stages .stage').removeClass('active');
          $('#checkout section.'+newSection).slideDown();
          $('#checkout .description.'+newSection).addClass('active');
          $('#checkout .stages .stage.'+newSection).addClass('active');
          $('#checkout .actionstep button[type=submit]').html("Donate "+$('#checkout .amounts #amountstr').val());
          return false;
        });
        $('#checkout section.stage-2 .form-control').blur(function() {
          var empty = $('#checkout section.stage-2 .form-control:not(#instructions)').filter(function() {
            return this.value === "";
          });
          var emailaddress = $('#checkout section.stage-2 #email').val();
          var fullname = $('#checkout section.stage-2 #firstname').val() + " ";
          fullname = fullname.concat($('#checkout section.stage-2 #lastname').val());
          if(!empty.length && validateEmail(emailaddress)) {
            $('#checkout section.stage-2 .next').removeAttr('disabled');
            $('#checkout section.stage-3 #cardholder_name').val(fullname);
          } else {
            $('#checkout section.stage-2 .next').attr('disabled',true);
          }
        });
        $('#checkout section.stage-3 .form-control').blur(function() {
          var empty = $('#checkout section.stage-3 .form-control').filter(function() {
            return this.value === "";
          });
//           if(!empty.length && (grecaptcha && grecaptcha.getResponse().length !== 0)) {
          if(!empty.length) {
            $('#checkout section.stage-3 .submit').removeAttr('disabled');
          } else {
            $('#checkout section.stage-3 .submit').attr('disabled',true);
          }
        });


        function updateCountdown() {
          var now = new Date().getTime();
          var targetDate = new Date("2025-05-10T10:00:00").getTime(); // Set your target date and time
          var timeRemaining = targetDate - now;

          if (timeRemaining <= 0) {
            $("#countdown").html("EXPIRED");
            clearInterval(intervalId); // Stop the timer when it reaches zero
            return;
          }

          var days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
          var hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

          $("#HoursCountdownToOpen").html("Opening <a href='/event/community-day-2/' style='text-decoration: underline'>May 10</a>, in " + days + " days " + hours + " hours and " + minutes + " minutes!");
        }

        updateCountdown(); // Call it once immediately to avoid delay
        var intervalId = setInterval(updateCountdown, 1000); // Update every second

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // PRODUCTS
    'woocommerce': {
      init: function() {
        $('.feat-prod-wrap .item .row .col-sm-6').matchHeight();
      },
      finalize: function() {
      }
    },
    // EVENTS SINGLE page
    'single_tribe_events': {
      init: function() {
        $('.header-text .page-title').find(':contains("\:")').each(function() {
            alert("found");
        });
        $('.header-text .page-title').each(function() {
          $(this).html($(this).html().replace(":", ""));
        });
        $('.banner #menu-primary-navigation .menu-calendar').addClass('active');

        $('.datepickerInput').change(function() {
          var newdate = $(this).val();
          window.location = "/calendar/?tribe-bar-date=" + newdate;
        });
      },
      finalize: function() {
      }
    },
    // SINGLE post
    'single_post': {
      init: function() {
        $('#menu-ancillary-navigation .menu-blog').addClass('active');
      }
    },
    // SINGLE press
    'single_press_release': {
      init: function() {
        $('#menu-ancillary-navigation .menu-press').addClass('active');
      }
    },
    'post_type_archive_tribe_events': {
      init: function() {
        $('.banner #menu-primary-navigation .menu-calendar').addClass('active');


        $('.datepickerInput').change(function() {
          var newdate = $(this).val();
          window.location = window.location.pathname + "?tribe-bar-date=" + newdate;
//           window.location = "/calendar/?tribe-bar-date=" + newdate;
        });
      },
      finalize: function() {
      }
    },

    // EXHIBITION SINGLE page
    'single_exhibition': {
      init: function() {
        $('.banner #menu-primary-navigation .menu-exhibitions').addClass('active');
      },
      finalize: function() {
      }
    },
    // COLLECTION SINGLE page
    'single_collection': {
      init: function() {
        $('.banner #menu-primary-navigation .menu-collections').addClass('active');
      },
      finalize: function() {
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
